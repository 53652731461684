.st0 {
    fill: rgba(7, 194, 7, 0.548);
    cursor: pointer;
    outline: none;
    transition: all 0.4s ease 0s;
    opacity: 0.5;
    stroke: white;
    justify-content: center;
    align-items: center;
    display: flex;
    stroke-width: 3px;
    outline: none;
    width: 100%;
  }
  .st0:hover {
    fill: #015e0d;
    opacity: 0.7;
       
   } 

   .st10 {
    fill: rgb(41, 225, 0);
    cursor: pointer;
    outline: none;
    transition: all 0.4s ease 0s;
    opacity: 0.7;
    stroke: white;
    justify-content: center;
    align-items: center;
    display: flex;
    stroke-width: 3px;
    outline: none;
    width: 100%;
  }
  .st10:hover {
    fill: #00580c;
    opacity: 0.9;
       
   } 
   
   .st1{
      fill: transparent;
       stroke: white;
       stroke-width: 2px;
   }
  
   .st2{
      fill:rgba(0, 0, 0, 0.436);
       stroke: rgba(255, 255, 255, 0);
  
   }
   .st3{
    fill: #7c1010bd;
    stroke: white;
    stroke-width: 2px;
    opacity: 0.6;
   }
   .object-filter-select {
    stroke: black;
    stroke-width: 1px;
    fill: white;
    cursor: pointer;
   }
   .object-filter-select:hover {
    fill: rgba(0, 30, 151, 0.9);
   }
   .object-filter-select:active {
    fill: rgb(144, 2, 2);
   }
   .object-filter-container {
    fill: rgb(108, 108, 108)
   }
   .object-filter {
    fill: rgba(210, 105, 30, 0.528)
   }
   .object-filter-line {
    stroke: black;
   }
   .object-filter-text {
    background-color: orange;
    fill: orange;
    font-size: 20px;
    pointer-events: none;
   }